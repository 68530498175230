﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/workflow/WorkflowInbox';

interface BeforeLoadWorkflowDashboard {
    type: 'LOAD_WORKFLOWDASHBOARD';
}
interface LoadWorkflowDashboard {
    type: 'LOADWORKFLOWDASHBOARD';
    response: Models.ResponseWorkflowInbox;
    status: number;
    statusMessage: String;
}
interface FailLoadWorkflowDashboard {
    type: 'LOADWORKFLOWDASHBOARDFAIL';
    status: number;
    statusMessage: any;
}

interface ClearLoadWorkflowDashboard {
    type: 'CLEARLOADWORKFLOWDASHBOARD';
    status: number;
    statusMessage: any;
}

type KnownAction =
    | LoadWorkflowDashboard
    | FailLoadWorkflowDashboard
    | ClearLoadWorkflowDashboard
    | BeforeLoadWorkflowDashboard;

export const actionCreators = {
    requestWorkflowDashboard:
        (
            p: boolean,
            method: String,
            user_id: string,
            token: string = '',
            page: string,
            page_size: string,
            wf_type: string,
            sids: string[],
            searchReq: Models.searchValue,
            wfStatus: string[]
        ): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const appState = getState();
                console.log('call actionCreators : requestWorkflowDashboard');
                if (
                    appState &&
                    appState.workflowDashboard &&
                    p &&
                    method == 'POST'
                ) {
                    var sid = '';
                    sids.forEach((element) => {
                        sid += '&app_id=' + element;
                    });

                    var wfstatus = '';
                    wfStatus.forEach((element) => {
                        wfstatus += '&wf_status=' + element;
                    });
                    var accessToken = localStorage.getItem('WF_APPLICATION');
                    if (token) {
                        var jObj = { access_token: token };
                        accessToken = JSON.stringify(jObj);
                    }
                    var createdOnTo = searchReq.createByTo
                    if (createdOnTo) {
                        createdOnTo += '235900';
                    }
                    var updatedOnTo = searchReq.updatedTo
                    if (updatedOnTo) {
                        updatedOnTo += '235900';
                    }

                    var createdfrom = searchReq.createBy
                    if (createdfrom) {
                        createdfrom += '000000'
                    }

                    var updatefrom = searchReq.updatedFrom
                    if (updatefrom) {
                        updatefrom += '000000'
                    }
                    fetch(
                        '/v4/workflow/dashboard?user_id=' +
                        user_id +
                        '&doc_no=' +
                        searchReq.wfNo +
                        '&doc_year=' +
                        searchReq.fiscalYear +
                        '&doc_date_from=' +
                        createdfrom +
                        '&doc_date_to=' +
                        createdOnTo +
                        '&updated_from=' +
                        updatefrom +
                        '&updated_to=' +
                        updatedOnTo +
                        '&page=' +
                        page +
                        '&page_size=' +
                        page_size +
                        '&wf_type=' +
                        wf_type +
                        sid +
                        wfstatus +
                        '&requester_id=' +
                        searchReq.requester_id +
                        '&amount_from=' +
                        searchReq.docAmount +
                        '&amount_to=' +
                        searchReq.docAmountTo,
                        {
                            method: 'GET',
                            headers: {
                                Authorization: 'Bearer ' + accessToken,
                            },
                        }
                    )
                        .then((response) => {
                            console.log('requestWorkflowDashboard');
                            // console.log(response)
                            return response;
                        })
                        .then((data) => {
                            //console.log(data)
                            if (!data.ok) {
                                data.json().then((err) => {
                                    //console.log(err.message_res)
                                    console.log('errorrrr');
                                    console.log(err);
                                    dispatch({
                                        type: 'LOADWORKFLOWDASHBOARDFAIL',
                                        status: 204,
                                        statusMessage: err.message,
                                    });
                                });
                            } else {
                                data.json().then((data) => {
                                    console.log(data);
                                    var byDate;
                                    if (data.total_count > 0) {
                                        byDate = data.result_list.slice(0);
                                        byDate.sort(function (a, b) {
                                            var x, y;
                                            if (b.docDate != null) {
                                                x = b.docDate.toLowerCase();
                                            }
                                            if (a.docDate != null) {
                                                y = a.docDate.toLowerCase();
                                            }
                                            return x < y ? -1 : x > y ? 1 : 0;
                                        });
                                        data.result_list = byDate;
                                    } else {
                                        data = undefined;
                                    }
                                    dispatch({
                                        type: 'LOADWORKFLOWDASHBOARD',
                                        response:
                                            data as Models.ResponseWorkflowInbox,
                                        status: 200,
                                        statusMessage: '',
                                    });
                                });
                            }
                        });

                    dispatch({ type: 'LOAD_WORKFLOWDASHBOARD' });
                } else if (method == 'CLEAR') {
                    console.log('dispatch : CLEARLOADWORKFLOWDASHBOARD');
                    dispatch({
                        type: 'CLEARLOADWORKFLOWDASHBOARD',
                        status: 400,
                        statusMessage: '',
                    });
                }
            },
};

const unloadedState: Models.GetWorkflowInboxState = {
    isLoadWorkflowInbox: false,
};

export const reducer: Reducer<Models.GetWorkflowInboxState> = (
    state: Models.GetWorkflowInboxState | undefined,
    incomingAction: Action
): Models.GetWorkflowInboxState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WORKFLOWDASHBOARD':
            console.log('LOAD_WORKFLOWDASHBOARD');
            return {
                isLoadWorkflowInbox: false,
            };

        case 'LOADWORKFLOWDASHBOARD':
            console.log('LOADWORKFLOWDASHBOARD');
            return {
                isLoadWorkflowInbox: true,
                responseWorkflowInbox: action.response,
                statusWorkflowInbox: action.status,
            };

        case 'LOADWORKFLOWDASHBOARDFAIL':
            return {
                isLoadWorkflowInbox: true,
                messageWorkflowInbox: action.statusMessage,
                statusWorkflowInbox: action.status,
            };
        case 'CLEARLOADWORKFLOWDASHBOARD':
            return {
                isLoadWorkflowInbox: false,
                messageWorkflowInbox: '',
                statusWorkflowInbox: 400,
            };
        default:
            return state;
    }
};
