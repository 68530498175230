﻿import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Row, Col, Table, Accordion, Form } from "react-bootstrap";
import { FaPlusCircle, FaArrowCircleLeft, FaTrashAlt } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import * as ModelsLog from '../../models/log/Log'

import { ApplicationState } from "../../store";

import * as MasterCompanyStore from '../../store/company/MasterCompany'
import * as MasterCompanyModels from '../../models/company/MasterCompany'
type store = MasterCompanyModels.GetMasterCompanyState & typeof MasterCompanyStore.actionCreators

export interface optionValue {
    value: string
    label: string
}

export interface searchValue {
    wfNo: string,
    docAmount: string,
    docAmountTo: string,
    requestor: string,
    createBy: string,
    createByTo: string,
}

const customStyles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none', zIndex: 'auto' } : base;
    },
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
}

const inititem = {
    param1: "",
    param2: ""
};

const initSelect = {
    code: "",
    name: "",
    display: "",
};

//// รับค่าจากหน้าหลัก
//type Props = {};
//type ComposedProps = Props & RouteComponentProps;
//// redux
//type searchemployeeProps = Models.SearchPositionEmployeeState & typeof SearchEmployeeStore.actionCreators;
//type createemployeeProps = CreateModels.CreatePositionEmployeeState & typeof CreateEmployeeStore.actionCreators;
//type rescreateemployeeProps = CreateModels.ResCreatePositionEmployeeState & typeof CreateEmployeeStore.actionCreators
//type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

class SimulateWorkflowSearch extends React.Component<any, { dashboardStatus: any, checkedALL: boolean, checkedSLSN: boolean, checkedSLTS: boolean, checkedSRIC: boolean, sids: string[], pr1: any, pr2: any, option: Array<MasterCompanyModels.MasterCompanyList>, checkCompanyOption: boolean, valueSelect: any, wfnoSearch: ModelsLog.searchValue, blindCreateBy: string, blindCreateByTo: string, blindApproveBy: string, blindApproveByTo: string, authGroup: string, positionCode: string, empID: string, rows: any, valueSelectUse: any, isCreate: boolean }, store> {
    constructor(props: any) {
        super(props)
        this.state = {
            checkedALL: true,
            checkedSLSN: false,
            checkedSLTS: false,
            checkedSRIC: false,
            sids: ["*"],
            option: [],
            checkCompanyOption: true,
            valueSelect: [],
            wfnoSearch: {
                wfNo: "",
                wfLogType: "",
                docAmount: "",
                docAmountTo: "",
                requester_id: "",
                createBy: "",
                createByTo: "",
                updatedFrom: "",
                updatedTo: "",
            },
            dashboardStatus: {
                APPROVED: "",
                WAITING: "",
                CANCEL: "",
            },
            blindCreateBy: "",
            blindCreateByTo: "",
            blindApproveBy: "",
            blindApproveByTo: "",
            authGroup: "",
            positionCode: "",
            empID: "",
            rows: [inititem],
            valueSelectUse: [initSelect],
            isCreate: true,
            pr1: [],
            pr2: []
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // this.props.blindSidArr(this.state.option)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.props.GetMasterCompany.isLoadGetMasterCompany && this.props.GetMasterCompany.statusGetMasterCompany == 200 && this.state.checkCompanyOption) {
            this.setState({
                checkCompanyOption: false
            })
            var dataSelect: any = []
            for (let i = 0; i < this.props.GetMasterCompany.responseGetMasterCompany['result_list'].length; i++) {
                var data = {}
                data['value'] = this.props.GetMasterCompany.responseGetMasterCompany['result_list'][i]['sid']
                data['label'] = this.props.GetMasterCompany.responseGetMasterCompany['result_list'][i]['company_name']
                dataSelect.push(data)
            }
            this.setState({
                option: dataSelect
            })
        }
        if (this.props.isImport && this.props.importTemplateJson.length > 0 && this.props.importTemplateJson !== nextProps.importTemplateJson) {
            this.setState({ rows: this.props.importTemplateJson });
        }
    }

    handleInputChange = (newValue: any, action: any) => {
    };

    handleChangeSelect = (newValue: any, actionMeta: any) => {
        if (newValue.length != 0) {
            var sidArrValue: any = []
            if (newValue != '') {
                for (let i = 0; i < newValue.length; i++) {
                    sidArrValue.push(newValue[i].value)
                }
            } else {
                sidArrValue = []
            }
            this.setState({ valueSelect: sidArrValue })
        } else {
            this.setState({ valueSelect: [] })
        }
    };

    handleChange = (idx: number, mode: String) => (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        const rows = [...this.state.rows];
        if (mode == 'param1') {
            console.log(value);
            const item = {
                param1: value,
                param2: rows[idx].param2
            };
            rows[idx] = item;
            this.setState({
                rows
            });
            this.props.updateParamsInput(rows);
        } else if (mode == 'param2') {
            console.log(value);
            const item = {
                param1: rows[idx].param1,
                param2: value
            };
            rows[idx] = item;
            this.setState({
                rows
            });
            this.props.updateParamsInput(rows);
        }
    };

    onsubmit = () => {
        if (this.state.rows[0]['param1'] == "" || this.state.rows[0]['param2'] == "") {
            console.log('Required Param')
            Swal.fire('ไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ !', 'warning')
        } else if (this.state.isCreate) {
            console.log('success')
            this.setState({
                isCreate: false
            })
            /*const rows = [...this.state.rows];*/
            /*console.log(rows);*/

            /*this.props.requestSimulateWorkflow(true, "POST",  this.state.rows)*/
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    beforeHandleSearch() {
        if ((this.state.rows[0]['param1'] == "" || this.state.rows[0]['param2'] == "")) {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                text: 'โปรดกรอกเลขที่เอกสารก่อนค้นหาข้อมูล',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'ตกลง'
            })
        } else {
            console.log("success");
            const rows = [...this.state.rows];
            console.log(rows);
            var pr1ArrValue: any = []
            var pr2ArrValue: any = []
            for (var i = 0; i < this.state.rows.length; i++) {
                if (i == 0) {
                    pr1ArrValue.push(rows[i].param1);
                    pr2ArrValue.push(rows[i].param2);
                }
                else {
                    pr1ArrValue.push('|', rows[i].param1);
                    pr2ArrValue.push('|', rows[i].param2);
                }

            }
            this.setState({ pr1: pr1ArrValue })
            this.setState({ pr2: pr2ArrValue })
            console.log(pr1ArrValue);
            console.log(pr2ArrValue);
            this.props.simulatehandleSearch("", pr1ArrValue, pr2ArrValue)
        }
    }

    handleAddRow = () => {
        const item = {
            param1: "",
            param2: ""
        };
        const itemSelect = {
            code: "",
            name: "",
            display: ""
        };
        this.setState({
            rows: [...this.state.rows, item],
            valueSelectUse: [...this.state.valueSelectUse, itemSelect]
        });
    };

    handleRemoveSpecificRow = (name: string, idx: number) => () => {

        const rows = [...this.state.rows]
        const valueSelectUse = [...this.state.valueSelectUse];
        rows.splice(idx, 1)
        valueSelectUse.splice(idx, 1)
        this.setState({ rows, valueSelectUse })
    }

    render() {
        // console.log(this.state.rows);
        return (
            <div>
                <div className="master-data-content">
                    <div className="mat-box">
                        <Row >
                            <Col>
                                <div className="container">
                                    <Table className="table-bordered table-sm" hover size="sm">
                                        <thead>
                                            <tr className='text-center thead-light' style={{ width: '70%' }}>
                                                <th style={{ width: '10%', textAlignLast: "center" }}>#</th>
                                                <th style={{ width: '30%' }}>Param1</th>
                                                <th style={{ width: '30%' }}>Param2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.rows.map((item, idx) => (
                                                <tr id={"addr" + idx} key={idx}>
                                                    <td className='text-center'><Button className="mx-2" variant="outline-danger" size="sm" onClick={this.handleRemoveSpecificRow("addr" + idx, idx)}><FaTrashAlt /></Button></td>
                                                    <td>
                                                        <Form.Control type="text" value={this.state.rows[idx].param1} onChange={this.handleChange(idx, 'param1')} />
                                                    </td>
                                                    <td>
                                                        <Form.Control type="text" value={this.state.rows[idx].param2} onChange={this.handleChange(idx, 'param2')} />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className='text-center'><Button className="mx-2" variant="outline-info" size="sm" onClick={this.handleAddRow}><FaPlusCircle /></Button></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ paddingLeft: "25px" }}>
                            <div style={{ display: "inline", float: "right" }}>
                                <Button className="mx-2" variant="outline-info" size="sm" onClick={() => this.beforeHandleSearch()}>&nbsp;Simulate</Button>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

}
export default connect((state: ApplicationState) => ({ ...state.MasterCompany }))(SimulateWorkflowSearch)