﻿import * as React from "react";
import { connect } from "react-redux";
import Select from 'react-dropdown-select';
import { ApplicationState } from "../../store";
import Swal from 'sweetalert2'
import * as ConditionStore from '../../store/autocomplete/ConditionSearch'
import * as Models from '../../models/autocomplete/ConditionSearch'

type conditionProps = Models.GetConditionState & typeof ConditionStore.actionCreators

export interface inititem {
    code: string
    name: string
    display: string
};

const initSelect = {
    code: "",
    name: "",
    display: "",
};

class Search extends React.Component<any, { response: any, p: boolean, dataResult: Array<inititem>, dataSearch: {}, checkSearch: boolean, checkValue: boolean, company?: string, app_id: string, SearchValue: inititem }, conditionProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            p: true,
            dataResult: [],
            dataSearch: {},
            checkSearch: true,
            checkValue: true,
            company: undefined,
            app_id: '',
            SearchValue: {
                code: "",
                name: "",
                display: "",
            }
        }
    }

    componentDidMount() {
        var company = this.props.company

        // if (this.props.app_id != '') {
        //     console.log('---- Query with app_id ----')
        //     var appId: Array<string> = []
        //     appId.push(this.props.app_id)
        //     this.props.requestConditionSearch(true, "CLEAR", this.state.app_id)
        //     this.setState({
        //         checkValue: true,
        //         app_id: this.props.app_id,
        //         dataResult: []
        //     })
        //     this.props.requestConditionSearch(true, "POST", appId)
        // } else {
        //     //console.log('---- Query with Company ----')
        //     //this.props.requestConditionSearch(this.state.p, "POST", company)
        //     //this.setState({
        //     //    p: false,
        //     //    company: company
        //     //})
        //     Swal.fire({
        //         title: 'โปรดเลือก SID ก่อน!',
        //         text: 'โปรดเลือก SID ก่อนเลือกผู้อนุมัติ',
        //         icon: 'warning',
        //         showCancelButton: false,
        //         confirmButtonColor: '#3085d6',
        //         confirmButtonText: 'ยืนยัน!'
        //     }).then((result) => {
        //     })
        // }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { valueSearch, checkloop, response } = this.props
        //console.log(this.props.valueSearch)
        /*        if (valueSearch == "main") {
                  if (valueSearch != undefined && this.state.checkSearch == true) {
       
                       var blindAuto = this.state.dataSearch
                       var landingauto: inititem = {
                           code: valueSearch['emp_id'],
                           name: valueSearch['emp_name'],
                           display: valueSearch['emp_name']
                       }
                       blindAuto = landingauto
                      
                       this.setState({
                           dataSearch: blindAuto,
                           checkSearch: false
                       })
                   }
                   } else {
                        if (valueSearch.emp_id != "" && this.state.checkSearch == true) {
                            console.log("passssssssssssssss")
                           this.props.disloop("false");
                           var blindAuto = this.state.dataSearch
                           var landingauto: inititem = {
                               code: valueSearch['emp_id'],
                               name: valueSearch['emp_name'],
                               display: valueSearch['emp_name']
                           }
                           blindAuto = landingauto
                        
                           this.setState({
                               dataSearch: blindAuto,
                               checkSearch: false
                           })
        
                        }
                   
                   }*/

        // if (this.props.status == 200 && this.state.checkValue && this.props.response['result_list'] != null) {
        //     var dataArr: Array<inititem> = []
        //     var initialdata: inititem = {
        //         code: "",
        //         name: "",
        //         display: "",
        //     }
        //     dataArr.push(initialdata)
        //     var dataArrRes: Array<inititem> = []
        //     dataArrRes = dataArr.concat(this.props.response['result_list'])
        //     console.log(dataArrRes)
        //     this.setState({
        //         dataResult: dataArrRes,
        //         checkValue: false
        //     })
        // }

        // if (this.props.app_id != '' && this.props.app_id != this.state.app_id) {
        //     var appId: Array<string> = []
        //     appId.push(this.props.app_id)
        //     this.props.requestConditionSearch(true, "CLEAR", this.state.app_id)
        //     this.setState({
        //         checkValue: true,
        //         app_id: this.props.app_id,
        //         dataResult: []
        //     })
        //     this.props.requestConditionSearch(true, "POST", appId)
        // }

        if (this.props.status == 200 && this.props.valueSearch.code != "" && this.props.userAutocomplete.length != 0 && this.state.SearchValue.code == "") {
            const pattern: any = new RegExp(`^${this.props.valueSearch.code}$`, 'i');
            const value: any = this.props.userAutocomplete.find((item: inititem) => pattern.test(item.code));
            console.log(value)
            if (value != undefined) {
                this.setState({
                    SearchValue: value
                })
            } else {
                console.log('cannot find user :')
                console.log(this.props.valueSearch)
                console.log('--------------------------------')
                const Evalue: any = this.props.userAutocomplete.find((item: inititem) => item.code === this.props.valueSearch.code);
                console.log(Evalue)
                if (Evalue != undefined) {
                    this.setState({
                        SearchValue: this.props.valueSearch
                    })
                }
            }
            //console.log(this.state.SearchValue)
        }
    }

    render() {
        return (
            <Select
                options={this.props.userAutocomplete}
                searchBy="code"
                labelField="display"
                valueField="code"
                values={[this.props.userAutocomplete.find(item => item.code === this.props.valueSearch.code) ?? this.props.valueSearch]} // Hack around (I don't know it'll glitchy or won't in future)
                dropdownGap={5}
                required={true}
                onChange={(value) => this.props.handlechageAutocomplete(value)}
                loading={this.props.userAutocomplete.length === 0}
            />
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.conditionSearch, ...state.appState }), ({ ...ConditionStore.actionCreators }))(Search)
