﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { RequestOptions } from '../../models/Common';
import { ResponseList } from '../../models/Response';
import { WorkflowAssignment, WorkflowAssignmentStateStore } from '../../models/workflowreport/WorkflowAssignment';

interface RequestWorkflowAssignment {
    type: 'REQUEST_WORKFLOW_ASSIGNMENT';
}

interface ReceiveWorkflowAssignment {
    type: 'RECEIVE_WORKFLOW_ASSIGNMENT';
    data: ResponseList<WorkflowAssignment>;
}

interface FailureWorkflowAssignment {
    type: 'FAILURE_WORKFLOW_ASSIGNMENT';
    error: any
}

interface ClearWorkflowAssignment {
    type: 'CLEAR_WORKFLOW_ASSIGNMENT';
}

type KnownAction =
    | RequestWorkflowAssignment
    | ReceiveWorkflowAssignment
    | FailureWorkflowAssignment
    | ClearWorkflowAssignment;

export const actionCreators = {
    requestWorkflowAssignment: (options: RequestOptions<null, { sids?: string[], authgroup?: string, positioncode?: string, empid?: string }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        const searchSids = new URLSearchParams(params?.sids!.map(sid => ['sid', sid])).toString().replace('&', '?');
        delete params?.sids;
        const searchOptional = new URLSearchParams(params as any).toString();

        dispatch({ type: 'REQUEST_WORKFLOW_ASSIGNMENT' });

        fetch('/v1/workflow-assignment?' + searchSids + '&' + searchOptional, {
            method,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => {
                console.log('ngame', data)
                dispatch({ type: 'RECEIVE_WORKFLOW_ASSIGNMENT', data });
            })
            .catch(error => dispatch({ type: 'FAILURE_WORKFLOW_ASSIGNMENT', error }));
    }
}

const initialState: WorkflowAssignmentStateStore = {
    isLoading: false,
    response: null,
    status: 0,
    error: null
};

export const reducer: Reducer<WorkflowAssignmentStateStore> = (state = initialState, action: KnownAction) => {
    switch (action.type) {
        case 'REQUEST_WORKFLOW_ASSIGNMENT': {
            return {
                ...state,
                isLoading: true
            };
        }
        case 'RECEIVE_WORKFLOW_ASSIGNMENT': {
            return {
                ...state,
                isLoading: false,
                response: action.data,
                status: 200
            };
        }
        case 'FAILURE_WORKFLOW_ASSIGNMENT': {
            return {
                ...state,
                isLoading: false,
                status: 400,
                error: action.error
            };
        }
        case 'CLEAR_WORKFLOW_ASSIGNMENT': {
            return initialState;
        }
        default: return state;
    }
}