﻿import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Row, Col, Image, Tab, Table, Form, Spinner } from "react-bootstrap";
import Pagination from '@material-ui/lab/Pagination';
import { ApplicationState } from "../../store";

import * as LogModels from '../../models/log/Log'
import * as LogStore from '../../store/log/Log'

type store = LogModels.GetLogState & typeof LogStore.actionCreators

class WorkflowLogTable extends React.Component<any, { pageSize: any, pageTab: any, pageBtn: any, checkRequest: boolean, valueBatchAll: any, checkResponseTable: boolean }, store> {
    constructor(props: any) {
        super(props);

        this.haddleChangePage = this.haddleChangePage.bind(this)
        this.BeforehandleChangePage = this.BeforehandleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)

        this.state = {
            pageSize: 25,
            pageTab: null,
            pageBtn: [],
            checkRequest: false,
            valueBatchAll: [],
            checkResponseTable: true
        }
    }

    handlePageSize = (e) => {
        // console.log(typeof e.target.value)
        var parse = parseInt(e.target.value)
        this.setState({
            pageSize: parse
        })

        return this.haddleChangePage(this.props.pageTab, 1, parse)
    }

    BeforehandleChangePage(event, value) {
        console.log(value);

        return this.haddleChangePage(this.props.pageTab, value, null)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { countTab, pageTab, statusLog } = this.props
        if (nextProps.countTab != countTab) {
            console.log(pageTab + ' | ' + countTab)
            this.setState({
                pageTab: pageTab
            })
            if (pageTab != null) {
                this.haddleChangePage(pageTab, 1, null)
            } else {
                this.haddleChangePage(null, 1, null)
            }
        }
    }

    componentWillUnmount() {
        //this.props.requestLog(true, "POST", this.props.sids)
    }

    haddleChangePage = (key: any, pageNo: any, page_size: any) => {

        if(this.props.selectedlogType){
            this.props.requestLog(true, "CLEAR", this.props.sids)
            key = this.props.selectedlogType
        }

        if (page_size != undefined || page_size != null) {
            this.setState({
                pageSize: page_size
            })
        }

        if (key == null || key == undefined) {
            this.props.requestLog(true, "CLEAR", this.props.sids)
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.countTab / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            console.log(dataArr)
            this.setState({
                pageBtn: dataArr,
                checkRequest: true,
                valueBatchAll: [],
                checkResponseTable: true
            })

            this.props.requestLog(true, "POST", this.props.sids, '', 1, this.state.pageSize)
        } else {
            this.props.requestLog(true, "CLEAR", this.props.sids)
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.countTab / this.state.pageSize)
            console.log(countingPage)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            console.log(dataArr)
            this.setState({
                pageBtn: dataArr,
                checkRequest: true,
                valueBatchAll: [],
                checkResponseTable: true
            })

            this.props.requestLog(true, "POST", this.props.sids, key, pageNo, this.state.pageSize)
        }
    }

    render() {
        return (
            <div>
                {this.state.checkRequest &&
                    <div>
                        <Row>
                            <Col xs={6}>
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    <Form inline>
                                        <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                            Show
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            className="my-1 mr-sm-2"
                                            id="inlineFormCustomSelectPref"
                                            custom
                                            size="sm"
                                            value={this.state.pageSize}
                                            onChange={this.handlePageSize}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </Form.Control>
                                        <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                            entries
                                        </Form.Label>
                                    </Form>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                    <Pagination count={10} shape="rounded" onChange={this.BeforehandleChangePage} defaultPage={1} showFirstButton showLastButton />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className="table-bordered table-hover align-middle table-responsive-sm">
                                    <thead >
                                        <tr className='text-center table-active thead-light' style={{ width: '100%' }}>
                                            <th style={{ width: '10%', textAlignLast: 'center' }}>Log type</th>
                                            <th style={{ width: '25%', textAlignLast: 'center' }}>Module</th>
                                            <th style={{ width: '20%', textAlignLast: 'center' }}>Doc No.</th>
                                            <th style={{ width: '25%', textAlignLast: 'center' }}>Message</th>
                                            <th style={{ width: '10%', textAlignLast: 'center' }}>Create By</th>
                                            <th style={{ width: '10%', textAlignLast: 'center' }}>Create On</th>
                                        </tr>
                                    </thead>
                                    {!this.props.isLoadLog ? <tbody><tr><td colSpan={11} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                                    {this.props.isLoadLog && this.props.responseLog == undefined && this.props.statusLog == 204 ? <tbody><tr><td colSpan={11} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                                    <tbody>
                                        {this.props.statusLog == 200 && this.props.isLoadLog && this.props.responseLog != undefined && this.props.responseLog['total_count'] != 0 && this.props.responseLog['result_list'].map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.log_type}</td>
                                                    <td>{data.module}</td>
                                                    <td>{data.docno}</td>
                                                    <td>{data.message}</td>
                                                    <td>{data.created_by}</td>
                                                    <td>{data.created_on}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        )
    }
} export default connect((state: ApplicationState) => ({ ...state.Log }), ({ ...LogStore.actionCreators }))(WorkflowLogTable)