﻿import { Action, Reducer, combineReducers } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/remark/Remark'

// #region interface GetRemark
interface BeforeLoadRemark {
    type: 'LOAD_REMARK'
}
interface LoadRemark {
    type: 'LOADEDREMARK'
    response: Models.RemarkRes
    status: number,
    statusMessage: String
}
interface FailRemark {
    type: 'LOADREMARKFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadRemark {
    type: 'CLEARLOADREMARK'
}
// #endregion

// #region interface AddRemark
interface BeforeAddRemark {
    type: 'ADD_REMARK'
}
interface AddRemark {
    type: 'ADDDREMARK'
    response: string
    status: number,
    statusMessage: String
}
interface AddFailRemark {
    type: 'ADDREMARKFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddRemark {
    type: 'CLEARADDREMARK'
}
// #endregion

type KnownAction = LoadRemark | FailRemark | BeforeLoadRemark | ClearLoadRemark | BeforeAddRemark | AddRemark | AddFailRemark | ClearAddRemark

export const actionCreators = {
    requestGetRemark: (p: boolean, method: string, sid: string, wfid: string, instanceno: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestGetRemark')
        if (appState && appState.positionEmployee && p && method == 'GET') {
            await fetch(`/v1/configuration/remark?sid=` + sid + '&wfid=' + wfid + '&instanceno=' + instanceno, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADREMARKFAIL')
                            dispatch({ type: 'LOADREMARKFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADEDREMARK')
                            dispatch({ type: 'LOADEDREMARK', response: data as Models.RemarkRes, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_REMARK' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADREMARK' });
        }
    },

    requestAddRemark: (p: boolean, method: string, formData: Models.RemarkReq, fileUpload: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestAddRemark')
        if (appState && appState.positionEmployee && p && method == 'POST') {

            const form = new FormData();
            form.append("fileList", fileUpload);
            form.append("sid", formData.sid);
            form.append("wfid", formData.wfid);
            form.append("instanceno", formData.instanceno);
            form.append("type", formData.type);
            form.append("message", formData.message);
            form.append("status", formData.status);
            form.append("created_by", formData.created_by);
            form.append("remark_ref_code", formData.remark_ref_code);
            form.append("instance_ref_code", formData.instance_ref_code);
            
            console.log(formData);
            console.log(form);
            await fetch(`/v1/configuration/remark`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                    //'Content-Type': 'multipart/form-data',
                    //'Content-Type': 'application/json; charset=utf-8',
                    //'Accept': '/'
                },
                redirect: 'follow',
                body: form
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : EDITREMARKFAIL')
                            dispatch({ type: 'ADDREMARKFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        //data.json().then(data => {
                        console.log(data)
                        console.log('dispatch : EDITDREMARK')
                        dispatch({ type: 'ADDDREMARK', response: 'success', status: 200, statusMessage: '' });
                        //})
                    }
                })
            dispatch({ type: 'ADD_REMARK' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDREMARK' });
        }
    },
}

const unloadedState: Models.GetRemarkState = { isLoadGetRemark: false };
const unloadedAddState: Models.AddRemarkState = { isLoadAddRemark: false };

const reducerGet: Reducer<Models.GetRemarkState> = (state: Models.GetRemarkState | undefined, incomingAction: Action): Models.GetRemarkState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_REMARK':
            return {
                isLoadGetRemark: false,
            }

        case 'LOADEDREMARK':
            return {
                isLoadGetRemark: true,
                responseGetRemark: action.response,
                statusGetRemark: action.status
            }

        case 'LOADREMARKFAIL':
            return {
                isLoadGetRemark: true,
                messageGetRemark: action.statusMessage,
                statusGetRemark: action.status
            }

        case 'CLEARLOADREMARK':
            return {
                isLoadGetRemark: false,
                messageGetRemark: '',
                statusGetRemark: 400
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddRemarkState> = (state: Models.AddRemarkState | undefined, incomingAction: Action): Models.AddRemarkState => {
    if (state == undefined) {
        return unloadedAddState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_REMARK':
            return {
                isLoadAddRemark: false,
            }

        case 'ADDDREMARK':
            return {
                isLoadAddRemark: true,
                //responseAddRemark: action.response,
                statusAddRemark: action.status
            }

        case 'ADDREMARKFAIL':
            return {
                isLoadAddRemark: true,
                messageAddRemark: action.statusMessage,
                statusAddRemark: action.status
            }

        case 'CLEARADDREMARK':
            return {
                isLoadAddRemark: false,
                messageAddRemark: '',
                statusAddRemark: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetRemark: reducerGet, AddRemark: reducerAdd });