﻿import * as React from "react";
import { connect } from "react-redux";
import QueryString from "query-string"
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import WorkflowSearchIdTab from './WorkflowSearchIdTab'
import WorkflowReportAdminTable from './WorkflowReportAdminTable'
import { Button, Row, Col, Tab, Form, Table } from "react-bootstrap";
import { FaSync, FaFileExport, FaTimesCircle, FaCheckCircle } from "react-icons/fa";

import * as ModelsTypeReportAdmin from '../../models/workflow/WorkflowTypeReportAdmin'
import * as StoreTypeReportAdmin from '../../store/workflow/WorkflowTypeReportAdmin'

import * as ModelsWorkflowReportAdmin from '../../models/workflow/WorkflowInbox'
import * as StoreWorkflowReportAdmin from '../../store/workflow/WorkflowReportAdmin'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

import { ApplicationState } from "../../store";
import { Store } from "@material-ui/icons";
import { changeSidToName, numberWithCommas, parseJwt } from '../../modules/Common';
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';
import { Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
//import * as ModelApproveRejectWorkflow from '../../models/workflow/ApproveRejectWorkflow';

import * as ApproveRejectWorkflowAdminStore from '../../store/workflow/ApproveRejectWorkflowAdmin';
import * as ModelApproveRejectWorkflowAdmin from '../../models/workflow/ApproveRejectWorkflowAdmin';

type Store = ModelsTypeReportAdmin.GetWorkflowTypeReportAdminState
    & typeof StoreTypeReportAdmin.actionCreators
    | ModelsWorkflowReportAdmin.GetWorkflowInboxState
    & typeof StoreWorkflowReportAdmin.actionCreators

type AppStateProps = ModelsAppState.AppState
    & typeof AppState.actionCreators

class WorkflowReportAdmin extends React.Component<any, {
    wfStatus: any,
    modeSearchApproveDate: boolean,
    searchReq: ModelsTypeReportAdmin.searchValue,
    checkRequest: boolean,
    checkResponse: boolean,
    ResponseTable: Array<ModelsTypeReportAdmin.WorkflowTypeReportAdmin>,
    countResponse: any,
    userId: any,
    token: any,
    key: string,
    uniqueWfType: any,
    wfTypeColour: any,
    currentPage: any,
    initialTab: any,
    sids: string[],
    pageTab: any,
    countTab: any,
    SidArr: any,
    company: any,
    checkSearch: boolean,
    checkResponseTable: boolean,
    messageModal: boolean,
    message: string,
    modeApproveReject: string,
    successModalHeader: string,
    successModal: boolean,
    docNoList: any,
    ApprovedRejectBatch: boolean,
    HeaderText: string
},
    Store & AppStateProps> {

    constructor(props: any) {
        super(props)

        this.state = {
            modeSearchApproveDate: true,
            checkRequest: true,
            checkResponse: true,
            ResponseTable: [],
            countResponse: "",
            userId: "",
            token: "",
            key: "",
            uniqueWfType: [],
            wfTypeColour: ['#6D6875', '#B5838D', '#E5989B', '#FFB4A2', '#FFCDB2', '#CDB4DB', '#FFAFCC', '#BDE0FE', '#A2D2FF', '#0096C7'],
            currentPage: 0,
            initialTab: {},
            sids: ['*'],
            pageTab: "",
            countTab: 0,
            SidArr: [],
            company: undefined,
            wfStatus: [],
            messageModal: false,
            message: "",
            modeApproveReject: "",
            successModalHeader: "",
            successModal: false,
            docNoList: [],
            searchReq: {
                wfNo: "",
                fiscalYear: "",
                docAmount: "",
                docAmountTo: "",
                createBy: "",
                createByTo: "",
                requester_id: "",
                updatedFrom: "",
                updatedTo: "",
                userName: ""
            },
            ApprovedRejectBatch: false,
            checkSearch: false,
            checkResponseTable: true,
            HeaderText: "Workflow Report"
        }
        this.blindSidArr = this.blindSidArr.bind(this)
    }

    resetRefresh(key) {
        const { responseWorkflowTypeReport, statusWorkflowTypeReport } = this.props
        if (responseWorkflowTypeReport != undefined) {
            this.setState({ key: "" });
            this.setState({
                checkRequest: false,
                countResponse: "เอกสาร (" + this.state.countTab + " รายการ)",
                currentPage: 0,
                countTab: 0
            })
        } else {
            this.setState({ key: "" });
            this.setState({
                checkRequest: false,
                countResponse: "เอกสาร (0 รายการ)",
                uniqueWfType: [],
                currentPage: 0,
                countTab: 0
            })
        }
    }

    componentDidMount() {
        const { userId, token } = QueryString.parse(this.props.location.search);
        if (userId) {
            this.setState({
                userId: userId,
                token: token,
            })
        } else {
            var ls: any = localStorage.getItem("WF_APPLICATION");
            var jls: any = JSON.parse(ls)
            var access_token = parseJwt(jls.access_token)
            var extra = JSON.parse(access_token.extra)

            var user_id = ""
            if (extra.citizen_id) {
                console.log('Azure Login ID: ' + extra.citizen_id)
                user_id = extra.citizen_id
                this.setState({ userId: extra.citizen_id, token: jls.access_token })
            } else if (extra.username) {
                console.log('Local Login ID: ' + extra.sid)
                user_id = extra.username
                this.setState({ userId: extra.username, token: jls.access_token })
            }

            //this.props.requestWorkflowTypeReport(true, 'POST', jls.user_id, ["1mHUSXNKeGnm3yMz4k9vDoDBA3b"])
            //this.props.requestWorkflowTypeReportAdmin(true, 'POST', '*')

            //this.props.requestWorkflowTypeReportAdmin(true, 'POST', user_id, this.state.sids, this.state.searchReq, this.state.wfStatus)
            //var company = this.props.company
            //console.log(company)
            //if (company != this.state.company) {
            //    if (company) {
            //        this.props.requestWorkflowTypeReportAdmin(true, 'POST', user_id, this.state.sids, this.state.searchReq, this.state.wfStatus)
            //    }

            //    this.setState({
            //        company: company
            //    });
            //}

            //this.props.requestWorkflowTypeReportAdmin(true, 'POST', '', this.state.sids, this.state.searchReq, this.state.wfStatus)
            var company = this.props.company
            console.log(company)
            if (company != this.state.company) {
                //if (company) {
                //    this.props.requestWorkflowTypeReportAdmin(true, 'POST', '', this.state.sids, this.state.searchReq, this.state.wfStatus)
                //}

                this.setState({
                    company: company
                });
            }
        }
    }

    refreshOnUpdate = () => {
        // re-renders the component
        console.log("void refesh")
        this.setState({
            key: "refresh",
            uniqueWfType: [],
            currentPage: 0,
            countTab: 0,
            checkRequest: false
        });
        //this.props.requestWorkflowReport(this.state.checkRequest, "CLEAR", this.state.userId, this.state.sids, 1, '', '')
        this.props.requestWorkflowTypeReportAdmin(true, 'POST', '', this.state.sids, this.state.searchReq, this.state.wfStatus)
    };

    componentDidUpdate(nextProps: any, nextState: any) {
        const { responseWorkflowTypeReport, statusWorkflowTypeReport, isLoadApproveReject, statusResponseApproveReject, messageResponseApproveReject } = this.props
        if (statusWorkflowTypeReport == 200 && this.state.checkRequest) {
            console.log(responseWorkflowTypeReport['result_list'])
            if (responseWorkflowTypeReport['result_list'] != undefined) {
                var dataRs: any = []
                //var dataArr: any = {}
                var countArr: number = 0
                for (let i = 0; i < responseWorkflowTypeReport['result_list'].length; i++) {
                    countArr += parseInt(responseWorkflowTypeReport['result_list'][i].count)
                }
                for (let i = 0; i < responseWorkflowTypeReport['result_list'].length; i++) {
                    var result = dataRs.filter(item => item.wftype === responseWorkflowTypeReport['result_list'][i].wftype);
                    if (result.length > 0) {
                        result[0].count = (parseInt(result[0].count) + parseInt(responseWorkflowTypeReport['result_list'][i].count)).toString()
                    }
                    else {
                        dataRs.push(responseWorkflowTypeReport['result_list'][i])
                    }
                }

                this.setState({
                    uniqueWfType: dataRs
                })

                this.setState({
                    checkRequest: false,
                    countResponse: "เอกสาร (" + countArr + " รายการ)",
                    pageTab: null,
                    countTab: countArr,
                    initialTab: { wftype: null, count: countArr }
                })
                setTimeout(() => {
                    console.log(this.state.countResponse)
                }, 1000);
            } else {
                this.setState({
                    checkRequest: false,
                    countResponse: "",
                    pageTab: null,
                    countTab: 1,
                    uniqueWfType: [],
                    initialTab: { wftype: null, count: 0 }
                })

                setTimeout(() => {
                    console.log(this.state.countResponse)
                    console.log(this.state.key);
                }, 1000);
            }
        }

        if (this.state.key != "") {
            this.setState({ checkRequest: true, countResponse: "" })

            setTimeout(() => {
                console.log(this.state.countResponse)
            }, 1000);
        }

        if (isLoadApproveReject && statusResponseApproveReject == 200 && (this.state.modeApproveReject == "approved" || this.state.modeApproveReject == "reject")) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //this.props.requestApproveWorkflow(true, "CLEAR", workflowValueUse.userId, workflowValueUse.docNo, "");
            this.setState({
                messageModal: false,
                message: "",
                modeApproveReject: "",
                docNoList: [],
                successModal: true,
                ApprovedRejectBatch: true
            })
            //setTimeout(() => {
            //    console.log(this.props.response)
            //    Swal.fire({
            //        title: 'สำเร็จ!',
            //        text: 'กดปุ่มยืนยัน',
            //        icon: 'success',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ยืนยัน!'
            //    }).then((result) => {
            //if (result.value) {
            //    if (this.state.checkurl == "No_URL") {
            //window.location.reload();
            //    } else {
            //        this.setState({
            //            controlRedirect: true
            //        })
            //    }
            //    this.setState({
            //        checkapprove: true,
            //    })
            //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)

            //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            //}
            //    })
            //}, 500);
        } else if (isLoadApproveReject && statusResponseApproveReject > 200 && (this.state.modeApproveReject == "approved" || this.state.modeApproveReject == "reject")) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            //this.props.requestApproveWorkflow(true, "CLEAR", workflowValueUse.userId, workflowValueUse.docNo, "");
            try {
                var msg = JSON.parse(messageResponseApproveReject)
                this.setState({
                    messageModal: false,
                    message: "",
                    modeApproveReject: "",
                    docNoList: [],
                    ApprovedRejectBatch: true
                })
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        text: msg.message,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        this.props.requestRejectWorkflowList(true, "CLEAR", "");
                        this.refreshOnUpdate()
                        //if (result.value) {
                        //    if (this.state.checkurl == "No_URL") {
                        this.props.requestApproveWorkflowAdmin(true, "CLEAR", "", "", "");
                        this.refresh()
                        //    }
                        //    this.setState({
                        //        checkapprove: true,
                        //    })
                        //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        //}
                    })
                }, 500);
            } catch {
                this.setState({
                    messageModal: false,
                    message: "",
                    modeApproveReject: "",
                    docNoList: [],
                    ApprovedRejectBatch: true
                })
                setTimeout(() => {
                    Swal.fire({
                        title: 'ไม่สำเร็จ!',
                        html: messageResponseApproveReject,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                    }).then((result) => {
                        this.props.requestRejectWorkflowList(true, "CLEAR", "");
                        this.refreshOnUpdate()
                        //if (result.value) {
                        //    if (this.state.checkurl == "No_URL") {
                        this.props.requestApproveWorkflowAdmin(true, "CLEAR", "", "", "");
                        this.refresh()
                        //    }
                        //    this.setState({
                        //        checkapprove: true,
                        //    })
                        //    this.props.requestsearchworkflowInbox(true, "POST", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
                        //    //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        //}
                    })
                }, 500);
            }
        }
    }

    handdleChangeWftype = (key: any, index: any) => {

        let x = document.getElementsByClassName("trapezoid");
        for (let i = 0; i < x.length; i++) {
            if (x.length > 0) { x[i].classList.remove("active"); }
        }
        if (index != null) {
            var element = document.getElementById("trapezoidTap" + index)
            if (element) {
                element.classList.add('active')
                this.setState({
                    pageTab: key.wftype,
                    countTab: key.count,
                    currentPage: index + 1
                })
            }
        } else {
            var element = document.getElementById("trapezoidTapAll")
            if (element) {
                element.classList.add('active')
                //this.refresh()
                this.setState({
                    pageTab: key.wftype,
                    countTab: key.count,
                    currentPage: 0
                })
            }
        }
    }

    public blindSidArr(value: any) {
        console.log(value)
        this.setState({
            SidArr: value
        })
    }

    exportExcel = (check) => {
        const { searchReq } = this.state
        if (check == "TotalExport") {
            var accessToken = localStorage.getItem('WF_APPLICATION')
            if (this.state.token) {
                var jObj = { access_token: this.state.token }
                accessToken = JSON.stringify(jObj)
            }
            var pageTab;
            if (this.state.pageTab != null) {
                pageTab = this.state.pageTab;
            } else {
                pageTab = ""
            }

            var sid = ""
            this.state.sids.forEach(element => {
                sid += "&app_id=" + element
            });

            var wfstatus = ""
            this.state.wfStatus.forEach(element => {
                wfstatus += "&wf_status=" + element
            });
            var createdOnTo = searchReq.createByTo
            if (createdOnTo) {
                createdOnTo + '235900';
            }
            var updatedOnTo = searchReq.updatedTo
            if (updatedOnTo) {
                updatedOnTo + '235900';
            }

            var createdfrom = searchReq.createBy
            if (createdfrom) {
                createdfrom + '000000'
            }

            var updatefrom = searchReq.updatedFrom
            if (updatefrom) {
                updatefrom + '000000'
            }
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            fetch('/v4/workflow/report-admin?user_id=' + '' + '&doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&doc_date_from=' + createdfrom + '&doc_date_to=' + createdOnTo + '&updated_from=' + updatefrom + '&updated_to=' + updatedOnTo + '&page=' + 1 + '&page_size=' + 100000000 + '&wf_type=' + pageTab + sid + wfstatus + '&requester_id=' + searchReq.requester_id + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    if (!data.ok) {
                        data.json().then(err => {

                        })
                    } else {
                        data.json().then(item => {
                            console.log(item["result_list"])
                            if (item["result_list"] != null) {
                                this.exportExcelWFDashboard(item["result_list"])
                            }
                        })
                    }
                })
        }
        else if (check == "Export") {
            var node = "node"
            var wfList: any = [];
            var checkExport: boolean = false
            Array.from(document.querySelectorAll("input[name=" + node + "]"))
                .forEach((checkbox) => {
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb.checked) {
                        if (cb.dataset.filelist) {
                            var filelist = JSON.parse(cb.dataset.filelist)
                            wfList.push(filelist)
                        }
                        checkExport = true;
                    }
                })

            if (checkExport == false) {
                Swal.fire({
                    title: 'ไม่สำเร็จ!',
                    text: 'กรุณาเลือกข้อมูลที่ต้องการ Export',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!',
                    allowOutsideClick: false
                })
            } else {
                Swal.fire({
                    title: 'Export Excel?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.value) {
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        this.exportExcelWFDashboard(wfList)
                    }
                })
            }
        }
    }

    exportExcelWFDashboard = (wfList) => {
        var date = new Date();
        var filename = 'WorkflowReport_' + date.getTime().toString() + '.xlsx';
        var ExportWFList: any = [];
        wfList.map((item) => {
            let data = {}
            data["Batch ID"] = item.batchid == "" || item.batchid == undefined || item.batchid == null ? "NONE" : item.batchid;
            data["Subject"] = item.subject;
            data["Doc. No"] = item.docNo;
            data["Comapny"] = changeSidToName(item.app_id, this.props.GetMasterCompany.isLoadGetMasterCompany ? this.props.GetMasterCompany.responseGetMasterCompany.result_list : this.state.SidArr);
            data["Doc. Amount"] = numberWithCommas(item.docAmount);
            data["Doc. Currency"] = item.docCurrency;
            data["Requestor Name"] = item.requestorName;
            data["Current Approval Order"] = item.curtaskid + '/' + item.maxtaskid;
            data["Created On"] = item.createdOnFormatDateTime;
            data["Updated By"] = item.updated_byname;
            data["Updated On"] = item.updatedOnFormatDateTime;
            data["WF Status"] = item.wfstatus;
            ExportWFList.push(data)
        })

        var ws_list = XLSX.utils.json_to_sheet(ExportWFList);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws_list, "WorkflowReport");
        XLSX.writeFile(wb, filename);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
    }

    refresh = () => {
        // re-renders the component
        //this.props.requestWorkflowTypeReportAdmin(true, 'CLEAR', this.state.userId, this.state.sids, this.state.searchReq, this.state.wfStatus)
        //this.setState({
        //    key: "refresh",
        //    currentPage: 0,
        //    countTab: 0
        //});
        //this.props.requestWorkflowTypeReportAdmin(true, 'POST', this.state.userId, this.state.sids, this.state.searchReq, this.state.wfStatus)

        this.props.requestWorkflowTypeReportAdmin(true, 'CLEAR', '', this.state.sids, this.state.searchReq, this.state.wfStatus)
        this.setState({
            key: "refresh",
            currentPage: 0,
            countTab: 0,
            checkSearch: true
        });
        this.props.requestWorkflowTypeReportAdmin(true, 'POST', '', this.state.sids, this.state.searchReq, this.state.wfStatus)
    };

    handleSearch = (value: any, checkAll: any, searchReq: any, wfStatus: any, selectUserValue: string) => {
        var listWFStatus: any = []
        if (wfStatus.APPROVED != "") {
            listWFStatus.push(wfStatus.APPROVED)
        }
        if (wfStatus.WAITING != "") {
            listWFStatus.push(wfStatus.WAITING)
        }
        if (wfStatus.CANCEL != "") {
            listWFStatus.push(wfStatus.CANCEL)
        }

        var dataReq = searchReq

        console.log(searchReq)

        var createdOnTo = searchReq.createByTo
        if (createdOnTo) {
            createdOnTo + '235900';
        }
        var updatedOnTo = searchReq.updatedTo
        if (updatedOnTo) {
            updatedOnTo + '235900';
        }

        var createdfrom = searchReq.createBy
        if (createdfrom) {
            createdfrom + '000000'
        }

        var updatefrom = searchReq.updatedFrom
        if (updatefrom) {
            updatefrom + '000000'
        }

        dataReq['createBy'] = createdfrom
        dataReq['createByTo'] = createdOnTo
        dataReq['updatedFrom'] = updatefrom
        dataReq['updatedTo'] = updatedOnTo
        dataReq['userName'] = selectUserValue

        var sids = ['']
        //false
        if (checkAll) {
            sids = ["*"]
        }
        else {
            sids.shift()
            sids.push(value);
        }
        this.setState({
            sids: sids,
            searchReq: dataReq,
            wfStatus: listWFStatus
        });
        setTimeout(() => {
            //this.componentDidMount();
            this.refresh();
        }, 500);
    };

    resetCheckResponseTable = (key: boolean) => {
        console.log(key)
        if (key) {
            this.setState({
                checkResponseTable: true
            })
        } else {
            this.setState({
                checkResponseTable: false
            })
        }
    }

    onsuccessApproveReject = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        var messagePush: any = []
        messagePush = this.state.docNoList
        for (let i = 0; i < messagePush.length; i++) {
            messagePush[i]['message'] = this.state.message
        }
        this.setState({
            docNoList: messagePush
        })
        console.log(this.state.docNoList);

        if (this.state.modeApproveReject == "approved") {
            //this.props.requestWorkflowInbox(true, "Approve", "", this.state.dataAll);
            this.props.requestApproveWorkflowList(true, "PUT", this.state.docNoList);
        }
        else {
            //this.props.requestWorkflowInbox(true, "Reject", "", this.state.dataAll);
            this.props.requestRejectWorkflowList(true, "PUT", this.state.docNoList);
        }
    }


    handleApprove = () => {
        var wfList = this.prepareApproveReject();
        if (wfList.length > 0) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            setTimeout(() => {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.setState({
                    messageModal: true,
                    modeApproveReject: "approved",
                    successModalHeader: "Approved Status"
                })
            }, 500)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ไม่พบการเลือกข้อมูล กรุณาลองอีกครั้ง',
                icon: 'error',
                showCancelButton: false
            })
        }

        //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, "", wfType);
    }


    handleReject = () => {
        var wfList = this.prepareApproveReject();
        if (wfList.length > 0) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            setTimeout(() => {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                this.setState({
                    messageModal: true,
                    modeApproveReject: "reject",
                    successModalHeader: "Rejected Status"
                })
            }, 500)
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ไม่พลการเลือกข้อมูล กรุณาลองอีกครั้ง',
                icon: 'error',
                showCancelButton: false
            })
        }
        //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    prepareApproveReject = () => {
        var node = "node"
        var wfList: any = [];
        var wfArr: any = []
        var wfUse: any = [];
        var wfUnique: any = [];
        console.log(document.querySelectorAll("input[name=" + node + "]"))
        Array.from(document.querySelectorAll("input[name=" + node + "]"))
            .forEach((checkbox) => {
                var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb.checked) {
                    var docNo = cb.dataset.docno;
                    var wfInstanceNo = cb.dataset.wfinstanceno;
                    var wfType = cb.dataset.wftype;
                    var appIdCheck = cb.dataset.appid
                    var wfins = {
                        appId: appIdCheck,
                        userId: this.state.userId,
                        wfType: wfType,
                        docNo: [docNo],
                        instanceNo: [wfInstanceNo]
                    }
                    wfList.push(wfins)
                }
            });

        wfList.map(batch => {
            if (wfArr.indexOf(batch.appId) === -1) {
                wfArr.push(batch.appId)
            }
        });

        let result = wfList.filter((value, index, self) => self.findIndex((m) => m.appId === value.appId) === index);
        wfUnique.push(result)

        for (let i = 0; i < wfUnique[0].length; i++) {
            console.log(wfUnique[0][i].appId)
            var wfNum: any = []
            var wfInstanceNum: any = []
            for (let j = 0; j < wfList.length; j++) {

                if (wfUnique[0][i].appId == wfList[j].appId) {
                    wfNum.push(wfList[j].docNo[0])
                    wfInstanceNum.push(wfList[j].instanceNo[0])
                }
            }
            var wfins = {
                app_id: wfUnique[0][i].appId,
                user_id: wfUnique[0][i].userId,
                wf_type: wfUnique[0][i].wfType,
                doc_no_list: wfNum,
                instance_no_list: wfInstanceNum
            }
            wfUse.push(wfins)
        }

        this.setState({
            docNoList: wfUse
        })

        return wfList;
    }

    handlesuccessModal = () => {
        if (this.state.successModal) {
            this.props.requestApproveWorkflowAdmin(true, "CLEAR", "", "", "");
            this.refresh()
            this.setState({
                successModal: false,
                successModalHeader: "",
            })
        } else {
            this.setState({
                successModal: true
            })
        }
    }

    hamdleMessage = () => {
        if (this.state.messageModal) {
            this.setState({
                messageModal: false,
                message: "",
                docNoList: []
            })
        } else {
            this.setState({
                messageModal: true
            })
        }
    }

    onchangeMessage = (e: { target: { name: any; value: any; }; }) => {
        this.setState({
            message: e.target.value
        })
        setTimeout(() => {
            console.log(this.state.message)
        }, 500);
    }


    render() {
        var userId = this.state.userId
        var token = this.state.token
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            {this.state.HeaderText}
                        </span>
                    </div>
                    <div className="mat-box">
                        <Row style={{ border: 'solid 1px #E7E7E7', marginLeft: '1px', marginRight: '1px' }}>
                            <WorkflowSearchIdTab blindSidArr={this.blindSidArr} handleSearch={this.handleSearch} modeSearchApproveDate={this.state.modeSearchApproveDate} />
                        </Row>
                        {this.state.checkSearch &&
                            <Row>
                                <Col style={{ textAlign: 'left', paddingTop: '10px' }}>
                                    <div className="container">
                                        <Button variant="outline-primary" size="sm" onClick={() => this.refresh()} disabled={this.state.checkResponseTable}>
                                            <FaSync />
                                            &nbsp;Refresh
                                        </Button>
                                    </div>
                                </Col>
                                <Col style={{ textAlign: 'right', paddingTop: '10px' }} md={{ offset: 5 }}>
                                    <div className="container">
                                        {/*<Button variant="outline-success" size="sm" onClick={() => this.handleApprove()} disabled={this.state.checkResponseTable}>*/}
                                        {/*    <FaCheck />*/}
                                        {/*    &nbsp;Approve*/}
                                        {/*</Button>*/}
                                        <Button variant="outline-danger" size="sm" className="mx-1" onClick={() => this.handleReject()} disabled={this.state.checkResponseTable}>
                                            <FaTimesCircle />
                                            &nbsp;Reject
                                        </Button>
                                        <Button variant="outline-success" size="sm" onClick={() => this.exportExcel('Export')} disabled={this.state.checkResponseTable}>
                                            <FaFileExport />
                                            &nbsp;Export Excel
                                        </Button>
                                        &nbsp;
                                        <Button variant="outline-success" size="sm" onClick={() => this.exportExcel('TotalExport')} disabled={this.state.checkResponseTable}>
                                            <FaFileExport />
                                            &nbsp;Total Export Excel
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        }
                        {this.state.checkSearch &&
                            <Row >
                                <Col>
                                    <div className="container">
                                        <div className="mt-2">
                                            <Row className={this.state.checkResponseTable ? 'disable-div' : ''} style={{ zIndex: 900 }}>
                                                {this.state.uniqueWfType.length != 0 ?
                                                    <AppBar position="static" color="inherit" style={{ boxShadow: 'none', zIndex: 0 }}>
                                                        <Tabs
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            aria-label="scrollable auto tabs example"
                                                            value={this.state.currentPage}
                                                        //onChange={this.blindTabs}
                                                        >
                                                            <div className='nav-item' onClick={() => this.handdleChangeWftype(this.state.initialTab, null)}>
                                                                <a className='pointer'>
                                                                    <div className='trapezoid trapezoid-purple active' id='trapezoidTapAll' style={{ width: '230px' }}>
                                                                        <span>
                                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                                            {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : this.state.countResponse}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            {this.state.uniqueWfType.length != 0 ? this.state.uniqueWfType.map((data, index) => {
                                                                return (
                                                                    <div key={index} className='nav-item' onClick={() => this.handdleChangeWftype(data, index)}>
                                                                        <a className='pointer'>
                                                                            <div className='trapezoid' id={'trapezoidTap' + index} style={{ width: '230px', borderBottom: "32px solid " + this.state.wfTypeColour[index] + "" }}>
                                                                                <span>
                                                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                                                    {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : (data.wftypeName != null ? data.wftypeName : data.wftype) + ' (' + data.count + ' รายการ)'}
                                                                                </span>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            }) : undefined}
                                                        </Tabs>
                                                    </AppBar>
                                                    : undefined}
                                            </Row>
                                        </div>
                                        {userId && <WorkflowReportAdminTable userId={userId} key={this.state.key} checkkey={this.state.key} callbackkey={this.resetRefresh.bind(this)} pageTab={this.state.pageTab} countTab={this.state.countTab} sids={this.state.sids} sidArr={this.props.GetMasterCompany.isLoadGetMasterCompany ? this.props.GetMasterCompany.responseGetMasterCompany.result_list : this.state.SidArr} searchReq={this.state.searchReq} wfStatus={this.state.wfStatus} checkResponseTable={this.resetCheckResponseTable.bind(this)} ResponseTable={this.state.checkResponseTable} token={this.state.token} />}
                                    </div>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>

                <Modal isOpen={this.state.messageModal} toggle={() => this.hamdleMessage()} size="lg" backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => this.hamdleMessage()}>Message</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={5} onChange={this.onchangeMessage} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ color: 'red' }}>
                                ** เฉพาะ Workflow ที่อยู่ในสถานะ WAITING เท่านั้นที่จะถูก Reject
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/*  <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button>*/}
                        <Button variant="info" onClick={() => this.onsuccessApproveReject()}>Confirm</Button>
                        <Button variant="secondary" onClick={() => this.hamdleMessage()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.successModal} toggle={() => this.handlesuccessModal()} size="lg" backdrop="static" keyboard={false}>
                    <ModalHeader toggle={() => this.handlesuccessModal()}>{this.state.successModalHeader}</ModalHeader>
                    <ModalBody scrollable>
                        <Row>
                            <Col>
                                {this.props.isLoadApproveReject && this.props.statusResponseApproveReject == 200 && this.props.responseApproveReject.length != 0 && this.props.responseApproveReject['result_list'].map((item: ModelApproveRejectWorkflowAdmin.ApproveRejectWorkflowAdminResponse, idx) => {
                                    console.log(item)
                                    return (
                                        <Card className="shadow-sm p-1 mb-1 bg-white rounded">
                                            <CardBody>
                                                <Row>
                                                    <Col lg={2}>
                                                        <div style={{ alignContent: 'center', verticalAlign: 'middle', textAlign: 'center' }}>
                                                            {item.status == "SUCCESS" ? <React.Fragment><FaCheckCircle size={50} color="green" /></React.Fragment> : <React.Fragment><FaTimesCircle size={80} color="red" style={{ paddingTop: '30px' }} /></React.Fragment>}
                                                        </div>
                                                    </Col>
                                                    <Col lg={10}>
                                                        <Table className="table table-borderless table-hover" size="sm">
                                                            <tr className="require-field" style={{ width: '100%' }}>
                                                                <td className="font-weight-bold" style={{ width: '30%' }}>Document NO. :</td>
                                                                <td style={{ width: '70%' }}>{item.docNo}</td>
                                                            </tr>
                                                            <tr className="require-field" style={{ width: '100%' }}>
                                                                <td className="font-weight-bold" style={{ width: '30%' }}>Status :</td>
                                                                <td style={{ width: '70%' }}>{item.status}</td>
                                                            </tr>
                                                            <tr className="require-field" style={{ width: '100%' }}>
                                                                <td className="font-weight-bold" style={{ width: '30%' }}>Message :</td>
                                                                <td style={{ width: '70%' }}>{item.message}</td>
                                                            </tr>
                                                            {item.status != 'SUCCESS' ?
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Error Message :</td>
                                                                    <td style={{ width: '70%' }}>{item.errorMessage}</td>
                                                                </tr>
                                                                : ''}
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="danger" onClick={() => this.handlesuccessModal()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
} export default connect((state: ApplicationState) => ({
    ...state.appState,
    ...state.MasterCompany,
    ...state.WorkflowTypeReportAdmin,
    ...state.workflowReportAdmin,
    ...state.approveRejectWorkflowAdmin
}), ({
    ...AppState.actionCreators,
    ...StoreTypeReportAdmin.actionCreators,
    ...StoreWorkflowReportAdmin.actionCreators,
    ...ApproveRejectWorkflowAdminStore.actionCreators
}))(WorkflowReportAdmin)