﻿import * as React from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Form, Accordion, Table, Spinner } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaPlusCircle, FaKey, FaTrashAlt, FaRegCopy, FaAngleDown, FaSearch, FaFileDownload, FaFileExport, FaFileExcel, FaArrowRight } from 'react-icons/fa';
import Swal from 'sweetalert2'
import MasterEmployeeTable from './MasterEmployeeTable'
import { parseJwt } from '../../modules/Common';

import { ApplicationState } from "../../store";
import * as ModelMasterEmployee from '../../models/callbackconfig/MasterEmployee'
import * as MasterEmployeeStore from '../../store/callbackconfig/MasterEmployee'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

import * as MasterCompanyStore from '../../store/company/MasterCompany'
import * as MasterCompanyModels from '../../models/company/MasterCompany'
import { isNullOrUndefined } from "util";
import Pagination from '@material-ui/lab/Pagination';

export interface optionValue {
    value: string
    label: string
}

export interface optionSid {
    value: string
    checked: boolean
}

type Store = ModelMasterEmployee.GetMasterEmployeeState & typeof MasterEmployeeStore.actionCreators | MasterCompanyModels.GetMasterCompanyState & typeof MasterCompanyStore.actionCreators;
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

const style = { color: "red", fontSize: "1.1em" }

interface State {
    openModalImport: boolean;
    selectCompany: string;
    openModalSelectCom: boolean;
    ExportTemplate: string;
    userId: string;
    token: string;
    openModalAdd: boolean;
    optionSid: Array<MasterCompanyModels.MasterCompanyList>;
    employeeListAdd: ModelMasterEmployee.employeeListAdd;
    excelListEmployee: any;
    sidSelected: Array<string>;
    formDataReq: ModelMasterEmployee.listMasterEmployee;
    editMode: boolean;
    empid: string;
    sid: string;
    empname: string;
    reset: boolean;
    page: number;
    page_size: number;
    pageBtn: any;
    excelListEmployeeBind: any;
    isOpenDropdown: boolean;
}

class MasterEmployee extends React.Component<any, State, Store & AppStateProps> {
    private readonly inputOpenFileRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props);
        this.state = {
            userId: "",
            token: "",
            selectCompany: "",
            openModalAdd: false,
            openModalSelectCom: false,
            openModalImport: false,
            //optionSid: [
            //    { value: '1mHUSXNKeGnm3yMz4k9vDoDBA3b', label: 'บริษัท ศิลาสานนท์ จำกัด' },
            //    { value: '1mHUXA5gy4XC58NNwXG5Pv5jhXs', label: 'บริษัท ศิลาไทยสงวน (2540) จำกัด' },
            //    { value: '1s421NbVxBwtM1jpjp7eDh5x0sR', label: 'บริษัท สยามอุตสาหกรรมวัสดุทนไฟ จำกัด' },
            //    { value: '1s41kgyAnv4dfqxdRXzDVIEf7XJ', label: 'บริษัท ปูนซิเมนต์ไทย จำกัด' },
            //    { value: '1tRwkyq7ehP3pXS6ZLHiDmyhmlG', label: 'บริษัท คิวมิกซ์ซัพพลาย จำกัด' },
            //    { value: '1tRwb4XQ3WeYDg4Xl1vblmGM4Fj', label: 'บริษัท อีโค่ แพลนท์ เซอร์วิสเซส จำกัด' },
            //    { value: '1s41rStqmc1RMtOtQTPLAdH9hMF', label: 'บริษัท เอสซีไอ อีโค่ เซอร์วิสเซส จำกัด' },
            //    { value: '1JeqtQlfd6FuDpsFtUjp01mOR98', label: 'FocusoneLink' },
            //],
            optionSid: [],
            formDataReq: {
                id: "",
                EmployeeListAdd: []
            },
            employeeListAdd: {
                sid: "",
                empid: "",
                empname: "",
                firstname: "",
                lastname: "",
                companycode: "",
                purchaseplantcode: "",
                divisioncode: "",
                department: "",
                costcenter: "",
                email: "",
                phone: "",
                TRIAL987: ""
            },
            excelListEmployee: undefined,
            sidSelected: [],
            editMode: false,
            sid: "",
            empid: "",
            empname: "",
            reset: false,
            ExportTemplate: "",
            page: 1,
            page_size: 25,
            pageBtn: [],
            excelListEmployeeBind: undefined,
            isOpenDropdown: false
        }
        this.updatePassValue = this.updatePassValue.bind(this)
        this.onupdate = this.onupdate.bind(this)
        this.inputOpenFileRef = React.createRef()
    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)
        console.log(extra)

        var user_id = ""
        if (extra.citizen_id) {
            console.log('Azure Login ID: ' + extra.citizen_id)
            user_id = extra.citizen_id
            this.setState({ userId: extra.citizen_id, token: jls.access_token })
        } else if (extra.username) {
            console.log('Local Login ID: ' + extra.sid)
            user_id = extra.username
            this.setState({ userId: extra.username, token: jls.access_token })
        }

        if (this.props.GetMasterCompany.responseGetMasterCompany != undefined) {
            this.setState({
                optionSid: this.props.GetMasterCompany.responseGetMasterCompany.result_list
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { AddMasterEmployee, SearchMasterEmployee, EditMasterEmployee, AddImportMasterEmployee, ListGetMasterEmployee } = this.props
        if (this.props.GetMasterCompany.responseGetMasterCompany != undefined && this.state.optionSid.length == 0) {
            this.setState({
                optionSid: this.props.GetMasterCompany.responseGetMasterCompany.result_list
            })
        }

        if (AddMasterEmployee.isLaoadAddMasterEmployee && AddMasterEmployee.statusAddMasterEmployee == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddMasterEmployee(true, "CLEAR", this.state.userId)
                    //this.props.requestGetMasterEmployee(true, 'GET');
                    this.handleReset(true)
                    this.setState({
                        openModalAdd: false,
                        formDataReq: {
                            id: "",
                            EmployeeListAdd: []
                        },
                        employeeListAdd: {
                            sid: "",
                            empid: "",
                            empname: "",
                            firstname: "",
                            lastname: "",
                            companycode: "",
                            purchaseplantcode: "",
                            divisioncode: "",
                            department: "",
                            costcenter: "",
                            email: "",
                            phone: "",
                            TRIAL987: ""
                        },
                        sidSelected: [],
                        sid: "",
                        empid: "",
                        empname: ""
                    })
                }
            })
        } else if (AddMasterEmployee.isLaoadAddMasterEmployee && AddMasterEmployee.statusAddMasterEmployee != 200) {
            var msg = JSON.parse(AddMasterEmployee.messageAddMasterEmployee)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddMasterEmployee(true, "CLEAR", this.state.userId)
                    //this.props.requestGetMasterEmployee(true, 'GET');
                    this.handleReset(true)
                }
            })
        }

        if (SearchMasterEmployee.isLaoadSearchMasterEmployee && SearchMasterEmployee.statusSearchMasterEmployee == 200 && !this.state.editMode) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var dataSet = SearchMasterEmployee.responseSearchMasterEmployee.result_list[0].EmployeeListAdd[0]
            var dataReq = this.state.employeeListAdd
            dataReq.empid = dataSet['empid']
            dataReq.empname = dataSet['empname']
            dataReq.firstname = dataSet['firstname']
            dataReq.lastname = dataSet['lastname']
            dataReq.companycode = dataSet['companycode']
            dataReq.purchaseplantcode = dataSet['purchaseplantcode']
            dataReq.divisioncode = dataSet['divisioncode']
            dataReq.department = dataSet['department']
            dataReq.costcenter = dataSet['costcenter']
            dataReq.email = dataSet['email']
            dataReq.phone = dataSet['phone']

            var dataReqId: Array<string> = []
            for (let i = 0; i < SearchMasterEmployee.responseSearchMasterEmployee.result_list[0].EmployeeListAdd.length; i++) {
                dataReqId.push(SearchMasterEmployee.responseSearchMasterEmployee.result_list[0].EmployeeListAdd[i].sid)
            }
            this.props.requestSearchMasterEmployee(true, "CLEAR", this.state.userId)
            this.setState({
                editMode: true,
                employeeListAdd: dataReq,
                sidSelected: dataReqId,
                openModalAdd: true
            })
        } else if (SearchMasterEmployee.isLaoadSearchMasterEmployee && SearchMasterEmployee.statusSearchMasterEmployee != 200 && !this.state.editMode) {
            var msg = JSON.parse(SearchMasterEmployee.messageSearchMasterEmployee)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestSearchMasterEmployee(true, "CLEAR", this.state.userId)
                }
            })
        }

        if (EditMasterEmployee.isLaoadEditMasterEmployee && EditMasterEmployee.statusEditMasterEmployee == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        openModalAdd: false,
                        formDataReq: {
                            id: "",
                            EmployeeListAdd: []
                        },
                        employeeListAdd: {
                            sid: "",
                            empid: "",
                            empname: "",
                            firstname: "",
                            lastname: "",
                            companycode: "",
                            purchaseplantcode: "",
                            divisioncode: "",
                            department: "",
                            costcenter: "",
                            email: "",
                            phone: "",
                            TRIAL987: ""
                        },
                        sidSelected: [],
                        editMode: false
                    })
                    this.props.requestEditMasterEmployee(true, "CLEAR", this.state.userId)
                    //this.props.requestGetMasterEmployee(true, 'GET');
                    this.handleReset(true)
                }
            })
        } else if (EditMasterEmployee.isLaoadEditMasterEmployee && EditMasterEmployee.statusEditMasterEmployee != 200) {
            var msg = JSON.parse(AddMasterEmployee.messageAddMasterEmployee)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestEditMasterEmployee(true, "CLEAR", this.state.userId)
                    //this.props.requestGetMasterEmployee(true, 'GET');
                    this.handleReset(true)
                }
            })
        }

        if (AddImportMasterEmployee.isLaoadAddImportMasterEmployee && AddImportMasterEmployee.statusAddImportMasterEmployee == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddImportMasterEmployee(true, "CLEAR")
                    this.handleReset(true)
                    this.setState({
                        openModalImport: false,
                        excelListEmployee: undefined,
                        pageBtn: []
                    })
                }
            })
        } else if (AddImportMasterEmployee.isLaoadAddImportMasterEmployee && AddMasterEmployee.statusAddImportMasterEmployee != 200) {
            //var msg = JSON.parse(AddImportMasterEmployee.messageAddImportMasterEmployee)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                html: AddImportMasterEmployee.messageAddImportMasterEmployee,
                icon: 'error',
                width: 'auto',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddImportMasterEmployee(true, "CLEAR")
                    this.handleReset(true)
                }
            })
        }
    }

    onOpenModalAdd = () => {
        if (this.state.openModalAdd == true) {
            console.log('Close modal')
            this.setState({
                openModalAdd: false,
                editMode: false,
                formDataReq: {
                    id: "",
                    EmployeeListAdd: []
                },
                employeeListAdd: {
                    sid: "",
                    empid: "",
                    empname: "",
                    firstname: "",
                    lastname: "",
                    companycode: "",
                    purchaseplantcode: "",
                    divisioncode: "",
                    department: "",
                    costcenter: "",
                    email: "",
                    phone: "",
                    TRIAL987: ""
                },
                sidSelected: [],
            })
        } else if (this.state.openModalAdd == false) {
            console.log('Open modal')
            this.setState({
                openModalAdd: true
            })
        }
    }

    handleSave = () => {
        if (this.state.employeeListAdd.empid != "") {
            var employeeListAdd: Array<ModelMasterEmployee.employeeListAdd> = []
            var sidSelected: Array<string> = this.state.sidSelected
            var dataList = this.state.employeeListAdd
            var reqEmpid = this.state.employeeListAdd.empid
            for (let i = 0; i < sidSelected.length; i++) {
                console.log(sidSelected[i])
                //dataList.sid = sidSelected[i].toString()
                dataList.sid = this.updatePassValue(sidSelected[i])
                //dataList.sid = this.updatePassValue(JSON.parse(JSON.stringify(sidSelected[i])))
                dataList.empname = dataList.firstname
                console.log(JSON.stringify(dataList))
                //employeeListAdd.push(dataList)
                this.addToArray(dataList, employeeListAdd.push.bind(employeeListAdd));
                console.log(employeeListAdd)
            }

            var formDataReq = this.state.formDataReq
            formDataReq['id'] = ""
            formDataReq['EmployeeListAdd'] = employeeListAdd

            console.log(JSON.stringify(formDataReq))
            if (!this.state.editMode) {
                this.props.requestAddMasterEmployee(true, "POST", this.state.userId, formDataReq)
            } else {
                this.props.requestEditMasterEmployee(true, "PUT", this.state.userId, reqEmpid, formDataReq)
            }
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        } else {
            Swal.fire({
                title: 'โปรดกรอก Emp ID',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง',
                allowOutsideClick: false
            }).then((result) => {
            })
        }
    }

    handleChangeInput = (e: any, key: string) => {
        console.log(key + '|' + e.target.value)
        var data = this.state.employeeListAdd
        data[key] = e.target.value
        this.setState({
            employeeListAdd: data
        })
        console.log(this.state.employeeListAdd)
    }

    handleSelectSid = (e: any, value: string) => {
        if (e.target.checked) {
            var data = this.state.sidSelected
            data.push(value)
            this.setState({
                sidSelected: data
            })
        } else {
            var data = this.state.sidSelected
            data.splice(data.indexOf(value), 1);
            this.setState({
                sidSelected: data
            })
        }
        console.log(this.state.sidSelected)
    }

    updatePassValue = (value: string) => {
        return value
    }

    addToArray = (element, pushFn) => {
        pushFn(JSON.parse(JSON.stringify(element)));
    }

    ExportTemplate = (Mode) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        if (Mode == "exportEmployee") {
            this.onOpenModalSelectCom();
        }
        this.setState({ ExportTemplate: Mode });
        if (Mode == "importTemplate") {
            var formDataReq: any = [];
            this.state.excelListEmployee.map((item) => {
                let data = {}
                data["sid"] = item.sid;
                data["empid"] = item.empid;
                data["empname"] = item.empname;
                data["firstname"] = item.firstname;
                data["lastname"] = item.lastname;
                data["companycode"] = item.companycode;
                data["purchaseplantcode"] = item.purchaseplantcode;
                data["divisioncode"] = item.divisioncode;
                data["department"] = item.department;
                data["costcenter"] = item.costcenter;
                data["email"] = item.email;
                data["phone"] = item.phone;
                data["TRIAL987"] = item.TRIAL987;
                data["createdby"] = item.createdby;
                data["createdon"] = item.createdon;
                data["updateby"] = item.updateby;
                data["updateon"] = item.updateon;
                formDataReq.push(data);
            })
            this.props.requestAddImportMasterEmployee(true, "POST", formDataReq)
        }
    }

    onupdate = (empid: string) => {
        console.log(empid)
        this.setState({

        })
        this.props.requestSearchMasterEmployee(true, 'GET', empid)
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    //handleChangeSearchInput = (e: any, key: string) => {
    //    if (key != "") {
    //        this.setState({
    //            ''+key+'': e.target.value
    //        })
    //    }
    //}

    handleReset = (value: boolean) => {
        this.setState({
            reset: value
        })
    }

    callbackReset = () => {
        this.setState({
            reset: false
        })
    }

    handleChangeCompany = (event) => {
        this.setState({ selectCompany: event.target.value });
    };

    onOpenModalSelectCom = () => {
        this.resetExportTemplate()
        if (this.state.selectCompany == "" && this.state.optionSid?.length !== 0) {
            this.setState({ selectCompany: this.state.optionSid[0].sid })
        }
        this.setState({ openModalSelectCom: !this.state.openModalSelectCom })
    }

    onOpenModalImport = () => {
        this.resetExportTemplate()
        this.setState({
            openModalImport: !this.state.openModalImport, excelListEmployee: undefined, excelListEmployeeBind: undefined, pageBtn: []
        });
    }

    resetExportTemplate = () => {
        this.setState({ ExportTemplate: "" });
    }

    handleClickFile = () => {
        this.inputOpenFileRef?.current?.click();
    }

    showDetailFile = (obj) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        if (obj.value != "") {
            this.resetExportTemplate()
            var formData = new FormData();
            formData.append('file', obj.target.files[0]);
            fetch("/v1/configuration/upload-excell",
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
                    },
                    body: formData
                }
            )
                .then(res => res)
                .then(
                    (result) => {
                        if (result.ok) {
                            result.json().then(data => {
                                this.setState({ excelListEmployee: data as ModelMasterEmployee.ExcelListemployee })
                                this.setPageBtn(this.state.page_size)
                                this.setDataExcelBind(this.state.page, this.state.page_size)
                            })
                        } else {
                            result.json().then(data => {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: data.message,
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'ยืนยัน',
                                })
                            })
                        }
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    },
                    (error) => {
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    }
                )
        }
    }

    setPageBtn = (page_size_num: any) => {
        const { excelListEmployee } = this.state
        var countingPage: any = 0
        var dataArr: any = []
        countingPage = Math.ceil(excelListEmployee.length / page_size_num)
        console.log(countingPage)

        for (let i = 0; i < countingPage; i++) {
            var data: any = {}
            data['page'] = i + 1
            data['pageTab'] = this.props.pageTab
            dataArr.push(data)
        }

        this.setState({
            pageBtn: dataArr
        })
    }

    BeforehandleChangePage = (event, value) => {
        console.log(value);
        this.setDataExcelBind(value, this.state.page_size)
        this.setState({
            page: value
        })

        //return this.haddleChangePage(this.props.pageTab, value)
    }

    setDataExcelBind = (page_num: any, page_size_num: any) => {
        const { excelListEmployee } = this.state
        if (excelListEmployee != undefined) {

            var datas: any = []

            for (var i = 0; i < excelListEmployee.length; i++) {
                var frist = page_size_num * (page_num - 1)
                var last = (page_size_num * page_num) - 1
                if (i <= last && i >= frist) {
                    datas.push(excelListEmployee[i])
                }
            }
            //console.log("test najaaaaaaaaaaaaaaaaaa")
            //console.log(datas)
            this.setState({ excelListEmployeeBind: datas })
        }
    }

    handlePageSize = (e) => {
        //console.log(typeof e.target.value)
        var parse = parseInt(e.target.value)
        this.setPageBtn(parse)
        this.setDataExcelBind(this.state.page, parse)
        this.setState({ page_size: parse });
    }

    checkValueEmployeeOldOrNew = (Status, Old, New, Index) => {
        var value: any
        if (Status == "Insert") {
            value = New
        } else if (Status == "Update") {
            var OldValue: ModelMasterEmployee.employeeListAdd = Old
            if (OldValue[Index] != New) {
                value = <span>{OldValue[Index]} <FaArrowRight style={style} /><span style={{ fontWeight: "bold" }}> {New}</span></span>;
            } else {
                value = New
            }
        }
        return value
    }

    private toggleIsOpenDropdown = () => this.setState({ isOpenDropdown: !this.state.isOpenDropdown });

    render() {
        return (
            <Container>
                <Row>
                    <Col className="import-export-actions">
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                Master Employee
                            </span>
                        </div>
                        <div className="d-block d-lg-none">
                            <Dropdown direction="left" size="sm" toggle={this.toggleIsOpenDropdown} isOpen={this.state.isOpenDropdown}>
                                <DropdownToggle className="btn btn-outline-success " caret>
                                    Import/Export
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        className="d-inline-flex align-items-center text-success"
                                        onClick={() => this.onOpenModalImport()}
                                    >
                                        <FaFileDownload />&nbsp;Import Template
                                    </DropdownItem>
                                    <DropdownItem
                                        className="d-inline-flex align-items-center text-success"
                                        onClick={() => this.onOpenModalSelectCom()}
                                    >
                                        <FaFileExport />&nbsp;Export Employee
                                    </DropdownItem>
                                    <DropdownItem
                                        className="d-inline-flex align-items-center text-success"
                                        onClick={() => this.ExportTemplate("exportTemplate")}
                                    >
                                        <FaFileExport />&nbsp;Export Template
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        className="d-inline-flex align-items-center text-info"
                                        onClick={() => this.onOpenModalAdd()}
                                    >
                                        <FaPlusCircle />&nbsp;Add Master Employee
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="d-none d-lg-block">
                            <Button className="mx-1" variant="outline-success" size="sm" style={{ display: '' }} onClick={() => this.onOpenModalImport()}><FaFileDownload />&nbsp;Import Template</Button>
                            <Button className="mx-1" variant="outline-success" size="sm" style={{ display: '' }} onClick={() => this.onOpenModalSelectCom()}><FaFileExport />&nbsp;Export Employee</Button>
                            <Button className="mx-1" variant="outline-success" size="sm" style={{ display: '' }} onClick={() => this.ExportTemplate("exportTemplate")}><FaFileExport />&nbsp;Export Template</Button>
                            <Button className="mx-1" variant="outline-info" size="sm" onClick={() => this.onOpenModalAdd()}><FaPlusCircle />&nbsp;Add Master Employee</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        <div className="mat-box">
                            <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                                <span>
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                    Search menu
                                    <div style={{ display: 'inline-block', float: 'right' }}>
                                    </div>
                                </span>
                            </div>
                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                <Row>
                                    <Col>
                                        {/*<Accordion defaultActiveKey="1">
                                        <Accordion.Toggle className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }} eventKey="0" as='a'>
                                            <span>
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                                            Search menu
                                                            <div style={{ display: 'inline-block', float: 'right' }}>
                                                    <FaAngleDown />
                                                </div>
                                            </span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <div className="mat-box shadow p-3 mb-5 bg-white rounded">
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Company Code :</Form.Label>
                                                            <Input type="select" name="select" bsSize='sm' onChange={(e) => this.setState({ sid: e.target.value })}>
                                                                <option value="" selected>เลือกบริษัทที่ต้องการค้นหา(ทั้งหมด)..</option>
                                                                {this.state.optionSid.map((item: MasterCompanyModels.MasterCompanyList, idx) =>
                                                                    <option value={item.sid}>{item.company_name}</option>
                                                                )}
                                                            </Input>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Employee ID :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.setState({ empid: e.target.value })} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>Employee Name :</Form.Label>
                                                            <Form.Control type="text" size='sm' onChange={(e) => this.setState({ empname: e.target.value })} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <div className="text-right mt-4">
                                                            <Button variant="info" size="sm" onClick={() => this.handleReset(true)}><FaSearch /> Search</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Collapse >
                                    </Accordion>*/}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                        <div className="mt-2">
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Company Code :</Form.Label>
                                                        {/*<Form.Control type="text" size='sm' onChange={(e) => this.setState({ sid : e.target.value })} />*/}
                                                        <Input type="select" name="select" bsSize='sm' onChange={(e) => this.setState({ sid: e.target.value })}>
                                                            <option value="" selected>เลือกบริษัทที่ต้องการค้นหา (ทั้งหมด)..</option>
                                                            {this.state.optionSid.map((item: MasterCompanyModels.MasterCompanyList, idx) =>
                                                                <option value={item.sid}>{item.company_name}</option>
                                                            )}
                                                        </Input>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Employee ID :</Form.Label>
                                                        <Form.Control type="text" size='sm' onChange={(e) => this.setState({ empid: e.target.value })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Employee Name :</Form.Label>
                                                        <Form.Control type="text" size='sm' onChange={(e) => this.setState({ empname: e.target.value })} />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="text-right mt-4">
                                                        <Button variant="outline-info" size="sm" onClick={() => this.handleReset(true)}><FaSearch /> Search</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col>
                                    <MasterEmployeeTable resetExportTemplate={this.resetExportTemplate.bind(this)} selectCompany={this.state.selectCompany} onupdate={this.onupdate} TotalComapny={this.state.optionSid} exportTemplate={this.state.ExportTemplate} keyReset={this.state.reset} callbackReset={this.callbackReset.bind(this)} empid={this.state.empid} empname={this.state.empname} sid={this.state.sid} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Modal isOpen={this.state.openModalAdd} toggle={() => this.onOpenModalAdd()} size="xl">
                    <ModalHeader toggle={() => this.onOpenModalAdd()}>Master Employee</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Emp ID :</Form.Label>
                                    <Form.Control type="text" placeholder="emp id" size="sm" id="empid" onChange={(e) => this.handleChangeInput(e, 'empid')} value={this.state.employeeListAdd.empid} />
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name :</Form.Label>
                                    <Form.Control type="text" placeholder="Frist name" size="sm" id="firstname" onChange={(e) => this.handleChangeInput(e, 'firstname')} value={this.state.employeeListAdd.firstname} />
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name :</Form.Label>
                                    <Form.Control type="text" placeholder="Last name" size="sm" id="lastname" onChange={(e) => this.handleChangeInput(e, 'lastname')} value={this.state.employeeListAdd.lastname} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col lg={7}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email :</Form.Label>
                                    <Form.Control type="email" placeholder="example@mail.com" size="sm" id="email" onChange={(e) => this.handleChangeInput(e, 'email')} value={this.state.employeeListAdd.email} />
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone :</Form.Label>
                                    <Form.Control type="text" placeholder="0123456789" size="sm" id="phone" onChange={(e) => this.handleChangeInput(e, 'phone')} value={this.state.employeeListAdd.phone} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Company Code :</Form.Label>
                                    <Form.Control type="text" placeholder="" size="sm" id="companycode" onChange={(e) => this.handleChangeInput(e, 'companycode')} value={this.state.employeeListAdd.companycode} />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Puchaser Plant Code :</Form.Label>
                                    <Form.Control type="text" placeholder="" size="sm" id="purchaseplantcode" onChange={(e) => this.handleChangeInput(e, 'purchaseplantcode')} value={this.state.employeeListAdd.purchaseplantcode} />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Division Code :</Form.Label>
                                    <Form.Control type="text" placeholder="" size="sm" id="divisioncode" onChange={(e) => this.handleChangeInput(e, 'divisioncode')} value={this.state.employeeListAdd.divisioncode} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Department :</Form.Label>
                                    <Form.Control type="text" placeholder="" size="sm" id="department" onChange={(e) => this.handleChangeInput(e, 'department')} value={this.state.employeeListAdd.department} />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Cost center :</Form.Label>
                                    <Form.Control type="text" placeholder="" size="sm" id="costcenter" onChange={(e) => this.handleChangeInput(e, 'costcenter')} value={this.state.employeeListAdd.costcenter} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            <Col>
                                <Form.Label>SID :</Form.Label>
                                {this.state.optionSid.map((item: MasterCompanyModels.MasterCompanyList, idx) =>
                                    <Form.Check
                                        label={item.company_name}
                                        name={item.sid}
                                        type="checkbox"
                                        id={item.sid}
                                        className="mx-5"
                                        onClick={(e) => this.handleSelectSid(e, item.sid)}
                                        checked={this.state.sidSelected.find(e => e == item.sid) != undefined ? true : false}
                                    //checked={true}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleSave}>Save</Button>
                        <Button variant="danger" onClick={() => this.onOpenModalAdd()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.openModalSelectCom} toggle={() => this.onOpenModalSelectCom()} size="md">
                    <ModalHeader toggle={() => this.onOpenModalSelectCom()}>Select Company</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm={11}>
                                <Input type="select" name="select" className="mx-4 form-control-sm mt-1" onChange={this.handleChangeCompany} defaultValue={this.state.selectCompany} value={this.state.selectCompany}>
                                    {this.state.optionSid.length != 0 && this.state.optionSid.map((data: MasterCompanyModels.MasterCompanyList, index) => {
                                        return (
                                            <option value={data.sid}>{data.company_name}</option>
                                        )
                                    })}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.ExportTemplate("exportEmployee")}>Export</Button>
                        <Button variant="danger" onClick={() => this.onOpenModalSelectCom()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.openModalImport} toggle={() => this.onOpenModalImport()} size="xl" style={{ maxWidth: '1800px' }}>
                    <ModalHeader toggle={() => this.onOpenModalImport()}>Import File</ModalHeader>
                    <ModalBody>
                        <input type="file" name="file" id="fileImport" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.showDetailFile} accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                        <Row>
                            <Col>
                                <Button className="mb-3" variant="info" size="sm" onClick={this.handleClickFile} ><FaFileExcel />&nbsp;Select File</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="mt-1">
                                    <Col xs={6}>
                                        <div className='d-flex justify-content-start my-2 my-lg-0'>
                                            <Form inline>
                                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                                    Show
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    className="my-1 mr-sm-2"
                                                    id="inlineFormCustomSelectPref"
                                                    custom
                                                    size="sm"
                                                    value={this.state.page_size}
                                                    onChange={this.handlePageSize}
                                                >
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </Form.Control>
                                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                                    entries
                                                </Form.Label>
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                                            <Pagination count={this.state.pageBtn.length > 0 ? this.state.pageBtn.length : undefined} shape="rounded" onChange={this.BeforehandleChangePage} defaultPage={1} showFirstButton showLastButton />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <div className="responsive-table">
                                            <Table className="table-bordered align-middle " size='sm'>
                                                <thead>
                                                    <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                                                        <th className="nowrap"></th>
                                                        <th className="nowrap">Status</th>
                                                        <th className="nowrap">SID</th>
                                                        <th className="nowrap">Emp ID</th>
                                                        <th className="nowrap">Emp name</th>
                                                        <th className="nowrap">Frist name</th>
                                                        <th className="nowrap">Last name</th>
                                                        <th className="nowrap">Company Code</th>
                                                        <th className="nowrap">Purchase Plant Code</th>
                                                        <th className="nowrap">Division Code</th>
                                                        <th className="nowrap">Department</th>
                                                        <th className="nowrap">costcenter</th>
                                                        <th className="nowrap">E-mail</th>
                                                        <th className="nowrap">Phone</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/*{!ListGetMasterEmployee.isLaoadListExcelMasterEmployee ? <tr><td colSpan={14} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}*/}
                                                    {this.state.excelListEmployeeBind == undefined ? < tr > <td colSpan={14} style={{ background: "#ccc", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                                    {this.state.excelListEmployeeBind != undefined && this.state.excelListEmployeeBind.map((item: ModelMasterEmployee.ExcelListemployee, idx) => {
                                                        return (
                                                            <tr key={idx} className={item.Status == "Update" ? "bg-warning nowrap" : item.Status == "Insert" ? "bg-success" : ""}>
                                                                <td className="nowrap"><span>{idx + 1}</span></td>
                                                                <td className="nowrap"><span style={{ fontWeight: "bold" }}>{item.Status}</span></td>
                                                                <td className="nowrap">{item.sid}</td>
                                                                <td className="nowrap">{item.empid}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.empname, "empname")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.firstname, "firstname")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.lastname, "lastname")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.companycode, "companycode")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.purchaseplantcode, "purchaseplantcode")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.divisioncode, "divisioncode")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.department, "department")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.costcenter, "costcenter")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.email, "email")}</td>
                                                                <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.Status, item.employee_old, item.phone, "phone")}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.ExportTemplate("importTemplate")}>Save</Button>
                        <Button variant="danger" onClick={() => this.onOpenModalImport()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        )
    }
} export default connect((state: ApplicationState) => ({ ...state.appState, ...state.MasterEmployee, ...state.MasterCompany }), ({ ...MasterEmployeeStore.actionCreators, ...AppState.actionCreators }))(MasterEmployee)