﻿import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/SearchWorkflowInbox'

interface LoadSearchWorkflowDashboard {
    type: 'LOADSEARCHWORKFLOWDASHBOARD'
    response: Models.ResponseWorkflowInboxValue
    status: Number
    statusMessage: String
}

interface FailSearchWorkflowDashboard {
    type: 'FAILSEARCHWORKFLOWDASHBOARD'
    status: number,
    statusMessage: any
}

interface ClearSearchWorkflowDashboard {
    type: 'CLEARSEARCHWORKFLOWDASHBOARD'
    response: Models.ResponseWorkflowInboxValue
    status: Number
    statusMessage: String
}

type KnownAction = LoadSearchWorkflowDashboard | FailSearchWorkflowDashboard | ClearSearchWorkflowDashboard

export const actionCreators = {
    requestsearchworkflowDashboard: (p: boolean, method: String, wf_id: string, doc_no: string, user_id: string, wf_no: string, token: string = "", app_id: string, customAccessToken?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestsearchworkflowDashboard')
        if (appState && appState.searchWorkflow && p && method == 'POST' && wf_id) {
            var accessToken = customAccessToken || localStorage.getItem('WF_APPLICATION')
            if (token) {
                var jObj = { access_token: token }
                accessToken = JSON.stringify(jObj)
            }

            var wfIdUri = encodeURIComponent(wf_id)
            var docNoUri = encodeURIComponent(doc_no)
            var userIdUri = encodeURIComponent(user_id)
            var wfNoUri = encodeURIComponent(wf_no)
            var appIdUri = encodeURIComponent(app_id)

            await fetch('v4/workflow/dashboard-detail?wf_id=' + wfIdUri + '&doc_no=' + docNoUri + '&user_id=' + userIdUri + '&wf_no=' + wfNoUri + '&app_id=' + appIdUri, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'FAILSEARCHWORKFLOWDASHBOARD', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADSEARCHWORKFLOWDASHBOARD', response: data as Models.ResponseWorkflowInboxValue, status: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARSEARCHWORKFLOWDASHBOARD', status: 204, statusMessage: "", response: [] as any });
        } else if (method == "CLOSE") {
            dispatch({ type: 'FAILSEARCHWORKFLOWDASHBOARD', status: 204, statusMessage: 'error' });
        }
    }
}

const unloadedState: Models.GetWorkflowInboxValueState = { isLoadWorkflowInboxValue: false };

export const reducer: Reducer<Models.GetWorkflowInboxValueState> = (state: Models.GetWorkflowInboxValueState | undefined, incomingAction: Action): Models.GetWorkflowInboxValueState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADSEARCHWORKFLOWDASHBOARD':
            return {
                isLoadWorkflowInboxValue: true,
                responseWorkflowInboxValue: action.response,
                statusWorkflowInboxValue: action.status
            }

        case 'FAILSEARCHWORKFLOWDASHBOARD':
            return {
                isLoadWorkflowInboxValue: true,
                messageWorkflowInboxValue: action.statusMessage,
                statusWorkflowInboxValue: action.status
            }

        case 'CLEARSEARCHWORKFLOWDASHBOARD':
            return {
                isLoadWorkflowInboxValue: false,
                responseWorkflowInboxValue: action.response,
                statusWorkflowInboxValue: 400
            }
        default: return state;
    }
}